import React, { useEffect, useState } from "react";
import FrameUploadForm from 'components/web/cmmn/FrameUploadForm';
import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { Form } from "react-bootstrap"
import { callApi } from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useBrch } from "components/web/auth/BrchProvider";
import BrchNmSelectBox from "components/web/cmmn/BrchNmSelectBox"
import EqmtNoSelectBox from "components/web/cmmn/EqmtNoSelectBox";
import CmmnCdUploadModal from "components/web/cmmn/CmmnCdUploadModal";


const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 15px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 85px;
    max-width: 205px;
    min-width: 205px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"], input[type="password"] {
        max-width: 650px;
        width: 650px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-gray-btn {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        border-radius: 80px;
        background-color: ${({ theme }) => theme.colors.gray5} !important;
        width: 50%;
        padding: 10px 6px;
        border-color: ${({ theme }) => theme.colors.gray5} !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:20px;
    }
`;

const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-wrap {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
`;

export default function FrameUpload() {
    const {t} = useTranslation();
    const { brchId } = useBrch();
    const brchClsfCd = localStorage.getItem("brchClsfCd");

    const date = new Date();
    const today = date.getFullYear() + String(date.getMonth() + 1).padStart(2, "0") + String(date.getDate()).padStart(2,"0") + String(date.getHours()).padStart(2,"0") + String(date.getMinutes()).padStart(2,"0") + String(date.getSeconds()).padStart(2,"0");
    const [prefix,setPrefix] = useState(today);

    const [colorFrameIcon] = useState(`${prefix}/frame-color-image/color`);
    const [specialFrameIcon] = useState(`${prefix}/frame-color-image/special`);
    const [colorFrame] = useState(`${prefix}/frame-image/print/color`);
    const [specialFrame] = useState(`${prefix}/frame-image/print/special`);

    //전송용
    const defaultSendData = {
        backgroundClsf:"",
        background:"",
        brchId:0,
        eqmtNo:"",
        dirName:"",
        prefix:"",
    }
    const [sendData, setSendData] = useState(defaultSendData);

    //전송용 선택
    const [totalBackgroundOption,setTotalBackgroundOption] = useState([]);
    //httpService 공통코드에서 가져오는 코드 구현 필요
    useEffect(() => {
        apiSvc.GetCmmnCdList({cmmnCdId:11})
        .then((res) => {
            setTotalBackgroundOption(res);
        })
        .catch((err) => {
            console.error(err);
        })
    },[]);

    const backgroundClsfOption = [{"key":"color","value":"컬러"},{"key":"event","value":"이벤트"},];
    const [backgroundClsf, setBackgroundClsf] = useState("");

    //const backgroundColorOption = [{"key":"BLACK","value":"CBLACK"},{"key":"WHITE","value":"CWHITE"},{"key":"GRAY","value":"CGRAY"},{"key":"BLUE","value":"CBLUE"},{"key":"GREEN","value":"CGREEN"},{"key":"PURPLE","value":"CPURPLE"},{"key":"PURPLEMIX","value":"CPURPLEM"},{"key":"PURPLEOIL","value":"CPURPLEF"},{"key":"GREENMIX","value":"CGREENM"}];
    const backgroundColorOption = totalBackgroundOption.map((data) => data.cmmnCd.slice(0,1) === 'C' ? data:null).filter(data => data !== null);
    //const backgroundEventOption = [{"key":"MAGAZINE","value":"ECMAGZE"},{"key":"RAIN","value":"ESRAIN"},{"key":"ELEVATOR","value":"EHELVTR"},{"key":"SEASON","value":"ECSESN"},{"key":"TEEN1","value":"ECTEEN1"},{"key":"TEEN2","value":"ECTEEN2"},{"key":"LOCAL","value":"ECLOCAL"}];
    const backgroundEventOption = totalBackgroundOption.map((data) => data.cmmnCd.slice(0,1) === 'E' ? data:null).filter(data => data !== null);
    const [backgroundOption, setBackgroundOption] = useState("");
    const [background, setBackground] = useState("");

    const handleBackgroundClsfChange = (e) => {
        setBackgroundClsf(e.target.value);
        setSendData((prev) => ({
            ...prev,
            backgroundClsf:e.target.value
        }))
        
        if(e.target.value === 'color'){
            setBackgroundOption(backgroundColorOption);
        }else if(e.target.value === 'event'){
            setBackgroundOption(backgroundEventOption);
        }else{
            setBackgroundOption("");
        }
    };

    const handleBackgroundChange = (e) => {
        setBackground(e.target.value);
        setSendData((prev) => ({
            ...prev,
            background:e.target.value
        }))
    }

    //가맹점
    const [sendBrchId, setSendBrchId] = useState(0);
    const handleBrchDataChange = (e) => {
        const { name, value } = e.target;
        setSendBrchId(value);
        if(value === "" || value === 0 || value === null){
            setSendData((prev) => ({
                ...prev,
                brchId:0
            }))
        } else {
            setSendData((prev) => ({
                ...prev,
                brchId:value
            }))
        }
    }
    //장치
    const [sendEqmtNo, setSendEqmtNo] = useState(null);
    const handleEqmtDataChange = (e) => {
        const { name, value } = e.target;
        setSendEqmtNo(value);
        setSendData((prev) => ({
            ...prev,
            eqmtNo:value
        }))
    }

    //모달
    const [cmmnCdModalOpen, setCmmnCdModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const openCmmnCdModal = () => {
        setCmmnCdModalOpen(true);
    }
    
    const testoutput = () => {
        console.log(sendData);
        console.log(backgroundColorOption);
    }
    
    return(
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <h2>{t("frameUpdtPage.frmeBgrdUpdt")}</h2>
                    </div>
                    <p>Home {">"} {t("frameUpdtPage.svcMng")} {">"} {t("frameUpdtPage.frmeBgrdUpdt")}</p>
                </div>
            </div>
            {/* <button onClick={testoutput}>출력</button> */}
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            <InputTable>
                                <div className="col-2">
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.bgrdClsf")} *</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <Form.Select
                                                name={"backgroundClsf"}
                                                value={backgroundClsf}
                                                onChange={handleBackgroundClsfChange}
                                                className={""}
                                            >
                                                <option value="">선택</option>
                                                {backgroundClsfOption.map((data,idx) => (
                                                    <option key={data.key} value={data.key}>
                                                        {data.value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </InputTableBody>
                                    </div>
                                        
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.bgrd")} *</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <Form.Select
                                                name={"background"}
                                                value={background}
                                                onChange={handleBackgroundChange}
                                                className={""}
                                            >
                                                <option value="">선택</option>
                                                {backgroundOption && backgroundOption.map((data,idx) => (
                                                    <option key={data.cmmnCd} value={data.cmmnCd}>
                                                        {data.cmmnCdNm}
                                                    </option>
                                                ))}
                                            </Form.Select>

                                            <button className="round-filled-gray-btn" onClick={() => {openCmmnCdModal(); setModalTitle("프레임 배경유형 코드 추가")}}>코드 추가</button>
                                        </InputTableBody>

                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.brch")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <BrchNmSelectBox
                                                defaultVal={""}
                                                tagName={"brchId"}
                                                defaultOptTxt={"선택"}
                                                brchClsfCd={"JMC"}
                                                //frncId={27}
                                                onSelectChange={handleBrchDataChange}
                                                //disabled={brchClsfCd !== "KSC"}
                                            />
                                        </InputTableBody>
                                    </div>
                                        
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.eqmt")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <EqmtNoSelectBox
                                                defaultVal={""}
                                                tagName={"eqmtNo"}
                                                defaultOptTxt={"선택"}
                                                brchId={sendBrchId}
                                                onSelectChange={handleEqmtDataChange}
                                            />
                                        </InputTableBody>
                                    </div>
                                        
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.stdFrmeIcon")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"S_CF"} code={backgroundClsf==="color"?"CF":"SF"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrameIcon:specialFrameIcon} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.HighFrmeIcon")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"H_CF"} code={backgroundClsf==="color"?"CF":"SF"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrameIcon:specialFrameIcon} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnFrmeIcon")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"C_CF"} code={backgroundClsf==="color"?"CF":"SF"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrameIcon:specialFrameIcon} prefix={prefix}/>
                                    </InputTableBody>
                                </div>

                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.Cmmn4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"BF1"} code={"BF1"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.StdVertical4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF2"} code={"SF2"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.StdHorizontal4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF4"} code={"SF4"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.HighVertical4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF1"} code={"SF1"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.HightHorizontal5CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF3"} code={"SF3"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnVertical6CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF5"} code={"SF5"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                            </InputTable>
                        </div>
                    </div>
                </div>

            </DashBoardWrap>

            <CmmnCdUploadModal
                show={cmmnCdModalOpen}
                onHide={() => {setCmmnCdModalOpen(false); window.location.replace("/svc-mgt/frame-upload")}}
                title={modalTitle}
            />
        </div>
    )
}