'use client'

import TextFields from "components/web/UI/TextFields"
import BrchNmSelectBox from "components/web/cmmn/BrchNmSelectBox"
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { callApi } from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg"
import * as Yup from 'yup';
import styled from "styled-components";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import CmmnRadioBox from "components/mobile/cmmn/CmmnRadio";
import { getFormattedValue } from "util/CmmnUtils";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-wrap {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 15px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    max-width: 208px;
    min-width: 208px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"], input[type="password"] {
        max-width: 250px;
        width: 250px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;
const getValidationSchemaId = (t) => Yup.object().shape({
    userId: Yup.string()
        .required(t("alert.idInput"))
        .max(20, t("alert.idMaxOf20"))
        .test(
            'is-valid-id',
            t("alert.engAndNumInput"),
            (value) => !value || /^[a-zA-Z0-9]+$/.test(value)
        )
});

const getValidationSchema = (t) => Yup.object().shape({
    userId: Yup.string().required(t("alert.idInput")),
    userPwd: Yup.string().required(t("alert.pwdInput")),
    userPwdConfirm: Yup.string()
        .required(t("alert.pwdInputCheck"))
        .oneOf([Yup.ref('userPwd'), null], t("alert.noMatchUserPwd")),
    brchId: Yup.string().required(t("alert.brchNmSelect")),
    userNm: Yup.string().required(t("alert.nameSelect")),
    userTelNo: Yup.string().required(t("alert.telSelect")),
    useYn: Yup.string().required(t("alert.useYnSelect")),
});

export default function UserMgtDetail() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userSqno } = useParams();
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const location = useLocation();
    const { brchIdProps } = location.state || {};

    const useYnDataArr = [
        { value: "Y", label: t("userMgtPage.use") },
        { value: "N", label: t("userMgtPage.unused") },
    ];

    const defaultUserInfo = {
        brchId: "",
        userId: "",
        userNm: "",
        userPwd: "",
        userPwdConfirm: "",
        userTelNo: "",
        userEmalAddr: "",
        useYn: "Y",
        delYn: "N",
    }

    const [errors, setErrors] = useState({});
    const [userIdChkSte, setUserIdChkSte] = useState(false);
    const [userInfo, setUserInfo] = useState(defaultUserInfo);
    const [validationSchemaId, setValidationSchemaId] = useState(getValidationSchemaId(t));
    const [validationSchema, setValidationSchema] = useState(getValidationSchema(t));

    useEffect(() => {
        setValidationSchemaId(getValidationSchemaId(t));
        setValidationSchema(getValidationSchema(t));
    }, [t, i18n.language]);

    useEffect(() => {
        if (userSqno !== "0") {
            callApi("GET", null, `/web/api/svc-mgt/user/detail?userSqno=${userSqno}`)
                .then(res => {
                    // console.log(res);
                    setUserInfo(res);
                    setUserInfo((prevData) => ({
                        ...prevData,
                        userPwd: "",
                        userPwdConfirm: "",
                    }));
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, [userSqno]);

    const handleFormDataChange = (event) => {
        const { name, value } = event.target;
        let formattedValue = value;
        if (name === "userTelNo") {
            formattedValue = value.replace(/[^0-9]/g, "");
            if (formattedValue.length > 11) {
                formattedValue = formattedValue.slice(0, 11);
            }
        }
        setUserInfo((prevInfo) => ({
            ...prevInfo,
            delYn: 'N',
            [name]: formattedValue,
        }));
        if (name === "userId") {
            setUserIdChkSte(false);
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                if (formattedValue === '') { // 아이디가 빈칸일 때
                    newErrors[name] = t("alert.idInput");
                } else {
                    delete newErrors[name];
                }
                return newErrors;
            });
        } else {
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                if (formattedValue !== '') {
                    delete newErrors[name];
                }
                return newErrors;
            });
        }
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                validationSchema.validate(userInfo, { abortEarly: false })
                    .then(() => {
                        let reqCd = 'insert';
                        if (userSqno > 0) {
                            reqCd = 'update';
                        }
                        if (reqCd === 'insert') {
                            if (!userIdChkSte) {
                                alert("", t("alert.userIdCheck"), () => { });
                                return;
                            }
                            if (userInfo.brchId === "") {
                                return;
                            }
                            setErrors({});
                            callApi("POST", userInfo, `/web/api/svc-mgt/user/insert`)
                                .then(response => {
                                    if (response) {
                                        // console.log(response);
                                        alert("", t("alert.save"), () => { window.location.replace("/svc-mgt/user-mgt") });
                                    }
                                });
                        } else if (reqCd === 'update') {
                            if (userInfo.brchId === "") {
                                return;
                            }
                            setErrors({});
                            callApi("POST", userInfo, `/web/api/svc-mgt/user/update`)
                                .then(response => {
                                    if (response) {
                                        // console.log(response);
                                        alert("", t("alert.save"), () => { window.location.replace("/svc-mgt/user-mgt") });
                                    }
                                });
                        }
                    })
                    .catch((err) => {
                        const newErrors = {};
                        err.inner.forEach((error) => {
                            newErrors[error.path] = error.message;
                        });
                        setErrors(newErrors);
                    });
                break;

            case "cmmnListBtn":
                navigate("/svc-mgt/user-mgt");
                break;
            case "cmmnUserIdCheckBtn":
                setUserIdChkSte(false);
                validationSchemaId.validate(userInfo, { abortEarly: false })
                    .then(() => {
                        callApi("GET", { userId: userInfo.userId }, "/web/api/svc-mgt/user/exists")
                            .then(res => {
                                // console.log(res);
                                setUserIdChkSte(res);
                                if (res) {
                                    setErrors({ userId: { message: t("alert.availableId"), color: "#006db2" } });
                                } else {
                                    setErrors({ userId: { message: t("alert.existsId"), color: "#ef2929" } });
                                }
                            })
                    })
                    .catch((err) => {
                        const newErrors = {};
                        err.inner.forEach((error) => {
                            newErrors[error.path] = { message: error.message, color: "#ef2929" };
                        });
                        setErrors(newErrors);
                    })
                break;
            default: break;
        }
    }

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt={t("leftMenu.userMgt")} />
                        <h2>{t("leftMenu.userMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} {t("leftMenu.userMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            <h2>{userSqno === "0" ?
                                `${t("userMgtPage.user")} ${t("common.regLable")}`
                                :
                                `${t("userMgtPage.user")} ${t("common.updtLable")}`
                            }
                            </h2>
                            <InputTable>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.brch")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        {userSqno === "0" ?
                                            <BrchNmSelectBox
                                                defaultVal={userInfo.brchId}
                                                tagName={"brchId"}
                                                defaultOptTxt={t("common.selectLable")}
                                                onSelectChange={handleFormDataChange}
                                                errorTxt={errors.brchId}
                                            // brchClsfCd={props.userSqno === "0" && brchClsfCd === "KSC" ? "JMC" : ""}
                                            />
                                            :
                                            <BrchNmSelectBox
                                                defaultVal={userInfo.brchId}
                                                tagName={"brchId"}
                                                defaultOptTxt={t("common.selectLable")}
                                                onSelectChange={handleFormDataChange}
                                                frncId={brchIdProps}
                                                errorTxt={errors.brchId}
                                                disabled={true}
                                            />
                                        }
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.id")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        {userSqno === "0" ?
                                            <div className="dp-flex align-cen">
                                                <TextFields
                                                    type="text"
                                                    onChangeValue={handleFormDataChange}
                                                    value={userInfo?.userId || ""}
                                                    name="userId"
                                                />
                                                <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                                    value={t("userMgtPage.doubleCheck")}
                                                    onClick={() => handleCmmnBtnClick("cmmnUserIdCheckBtn")}
                                                />
                                                {errors.userId &&
                                                    <p style={{ color: errors.userId.color, marginLeft: "1rem", fontSize: "0.9rem" }}>
                                                        {errors.userId.message}
                                                    </p>}
                                            </div>
                                            :
                                            <div className="dp-flex align-cen">
                                                <TextFields
                                                    type="text"
                                                    onChangeValue={handleFormDataChange}
                                                    value={userInfo?.userId || ""}
                                                    disabled={true}
                                                    name="userId"
                                                    errorTxt={errors.userId}
                                                    className="readonly"
                                                />
                                            </div>
                                        }
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.name")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            onChangeValue={handleFormDataChange}
                                            value={userInfo?.userNm || ""}
                                            name="userNm"
                                            errorTxt={errors.userNm}
                                            autoComplete="new-password"
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.password")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="password"
                                            onChangeValue={handleFormDataChange}
                                            value={userInfo?.userPwd || ""}
                                            name="userPwd"
                                            errorTxt={errors.userPwd}
                                            autoComplete="new-password"
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.passwordConfirm")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="password"
                                            onChangeValue={handleFormDataChange}
                                            value={userInfo?.userPwdConfirm || ""}
                                            name="userPwdConfirm"
                                            errorTxt={errors.userPwdConfirm}
                                            autoComplete="new-password"
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.tel")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            onChangeValue={handleFormDataChange}
                                            value={getFormattedValue(userInfo?.userTelNo) || ""}
                                            name="userTelNo"
                                            placeholder={`“-”${t("userMgtPage.enterExcept")}`}
                                            errorTxt={errors.userTelNo}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.email")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            onChangeValue={handleFormDataChange}
                                            value={userInfo?.userEmalAddr || ""}
                                            name="userEmalAddr"
                                            errorTxt={errors.userEmalAddr}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.useYn")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <CmmnRadioBox
                                            dataArray={useYnDataArr}
                                            defaultVal={userInfo.useYn}
                                            tagName={"useYn"}
                                            onSelectChange={handleFormDataChange}
                                            errorTxt={errors.useYn}
                                            errorClassName={"w-50"}
                                        />
                                    </InputTableBody>
                                </div>
                                {userSqno === "0" ?
                                    <></>
                                    :
                                    <>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{userInfo?.regUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{userInfo?.chngUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{userInfo?.convRegDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{userInfo?.convChngDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                    </>
                                }
                            </InputTable>
                        </div>
                        <BtnWrap>
                            <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                                value={t("userMgtPage.userList")}
                                onClick={() => { handleCmmnBtnClick("cmmnListBtn") }}
                            />
                            <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                value={t("userMgtPage.userSave")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                        </BtnWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )

}