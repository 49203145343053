import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';


import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	Autosave,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	CloudServices,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	// Heading,
	Highlight,
	HorizontalLine,
	HtmlComment,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Paragraph,
	SelectAll,
	// ShowBlocks,
	// SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Style,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	// TextPartLanguage,
	TextTransformation,
	// Title,
	TodoList,
	Underline,
	Undo,
	// MediaEmbed,
	FileRepository,
	DragDrop,
	ButtonView,
	Plugin,
} from 'ckeditor5';

import translations from 'ckeditor5/translations/ko.js';

import 'ckeditor5/ckeditor5.css';

import './App.css';
import styled from 'styled-components';
import { callApi } from 'util/HttpService';
// import { alert } from 'components/web/cmmn/CmmnConfirm';

const EditorWrap = styled.div`
	width: 100%;
	box-sizing: border-box;
	z-index: 1;
	.ck-reset_all {
		position: relative;
		z-index: 4 !important;
	}
  	.editor-container__editor {
		width: 100%;
		max-width: 1152px;
		min-width: 695px;
  	}
  	.ck-editor__editable {
		min-height: 600px;
		font-size: 14px;
  	}
  	@media (max-width: 1600px) {
		.ck-editor__editable {
			min-height: 400px;
		}
  	}
	.ck-editor__editable em, .ck-editor__editable i {
 	   font-style: italic !important;
	}
	strong u {
    	font-family: 'Pretendard-Bold';
	}
`;
const debounce = (func, delay) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), delay);
	};
};

class VideoUpload extends Plugin {
	init() {
		const editor = this.editor;

		// 동영상 전용 블록을 정의
		editor.model.schema.register('videoBlock', {
			allowWhere: '$block',
			isObject: true,
			allowAttributes: ['src']
		});

		// HTML -> 모델로 변환 (업캐스트, 로딩 시)
		editor.conversion.for('upcast').elementToElement({
			view: {
				name: 'video',
				attributes: {
					src: true
				}
			},
			model: (viewElement, { writer: modelWriter }) => {
				const src = viewElement.getAttribute('src');
				return modelWriter.createElement('videoBlock', { src });
			}
		});

		// 모델 -> 뷰로 변환 (다운캐스트, 저장 시)
		editor.conversion.for('dataDowncast').elementToElement({
			model: 'videoBlock',
			view: (modelElement, { writer: viewWriter }) => {
				const src = modelElement.getAttribute('src');
				const videoElement = viewWriter.createContainerElement('video', {
					controls: 'controls',
					width: '500',
					src: src,
					style: 'display: block; margin: 0 auto;'
				});

				const sourceElement = viewWriter.createEmptyElement('source', {
					src: src,
					type: 'video/mp4'
				});

				viewWriter.insert(viewWriter.createPositionAt(videoElement, 0), sourceElement);

				return videoElement;
			}
		});

		// 편집 중일 때 모델 -> 뷰로 변환 (다운캐스트)
		editor.conversion.for('editingDowncast').elementToElement({
			model: 'videoBlock',
			view: (modelElement, { writer: viewWriter }) => {
				const src = modelElement.getAttribute('src');
				const videoElement = viewWriter.createContainerElement('video', {
					controls: 'controls',
					width: '500',
					src: src,
					style: 'display: block; margin: 0 auto;'
				});

				const sourceElement = viewWriter.createEmptyElement('source', {
					src: src,
					type: 'video/mp4'
				});

				viewWriter.insert(viewWriter.createPositionAt(videoElement, 0), sourceElement);

				return videoElement;
			}
		});

		// 비디오 업로드 버튼
		editor.ui.componentFactory.add('videoUpload', locale => {
			const view = new ButtonView(locale);
			view.set({
				icon: `<?xml version="1.0" encoding="UTF-8"?>
					<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
					<path d="m16.914,1h2.086c.621,0,1.215.114,1.764.322l-5.678,5.678h-4.172l6-6Zm7.086,6v-1c0-1.4-.579-2.668-1.51-3.576l-4.576,4.576h6.086ZM10.522,1l-6.084,6h3.648L14.086,1h-3.564ZM1.59,7L7.674,1h-2.674C2.243,1,0,3.243,0,6v1h1.59Zm22.41,2v9c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5v-9h24Zm-8.953,6.2l-4.634-2.48c-.622-.373-1.413.075-1.413.8v4.961c0,.725.791,1.173,1.413.8l4.634-2.48c.604-.362.604-1.238,0-1.6Z"/>
					</svg>`,
				label: '',
				tooltip: '동영상 삽입',
				withText: false
			});

			view.on('execute', () => {
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = 'video/mp4';
				input.click();

				input.onchange = () => {
					const file = input.files[0];
					// const maxLimit = 30 * 1024 * 1024;
					// if (file.size > maxLimit) {
					// 	alert("", "파일 사이즈가 30MB를 초과합니다.", () => {});
					// 	return;
					// }
					if (file) {
						const formData = new FormData();
						formData.append('file', file);
						formData.append('dirName', 'video');

						callApi("POST", formData, "/web/api/svc-mgt/psts/video-upload", {
							headers: {
								'Content-Type': 'multipart/form-data',
							}
						})
							.then(res => {
								console.log("S3 URL: ", res);
								if (res) {
									editor.model.change(writer => {
										// 동영상 블록을 추가
										const videoElement = writer.createElement('videoBlock', {
											src: res  // 업로드된 S3 동영상 URL
										});

										// 동영상 블록을 현재 커서 위치에 삽입
										editor.model.insertContent(videoElement, editor.model.document.selection);

										// 동영상 뒤에 빈 텍스트 블록을 추가해 텍스트를 쓸 수 있게 함
										const paragraphElement = writer.createElement('paragraph');
										editor.model.insertContent(paragraphElement, writer.createPositionAfter(videoElement));

										// 커서를 빈 텍스트 블록으로 이동시킴
										writer.setSelection(paragraphElement, 'in');
									});
								}
							})
							.catch(err => {
								console.error(err);
							});
					}
				};
			});

			return view;
		});
	}
}

export default function App({ name, value, onChangeValue, disabled }) {
	const editorRef = useRef(null);
	const [editorWidth, setEditorWidth] = useState('100%');

	useEffect(() => {
		const handleResize = debounce(() => {
			const windowWidth = window.innerWidth;
			// console.log(windowWidth);

			if (windowWidth >= 1700) {
				setEditorWidth('1152px');
			} else if (windowWidth > 1500) {
				setEditorWidth('838.1px');
			} else {
				setEditorWidth('695px');
			}
		}, 100);

		// 초기 설정 및 이벤트 리스너 등록
		handleResize();
		window.addEventListener('resize', handleResize);

		// Cleanup 함수: 이벤트 리스너 해제
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const editorConfig = {
		plugins: [
			AccessibilityHelp,
			Alignment,
			Autoformat,
			AutoImage,
			Autosave,
			Base64UploadAdapter,
			BlockQuote,
			Bold,
			CloudServices,
			Code,
			CodeBlock,
			Essentials,
			FindAndReplace,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			FullPage,
			GeneralHtmlSupport,
			// Heading,
			Highlight,
			HorizontalLine,
			HtmlComment,
			HtmlEmbed,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageTextAlternative,
			ImageToolbar,
			ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			Paragraph,
			SelectAll,
			// ShowBlocks,
			// SourceEditing,
			SpecialCharacters,
			SpecialCharactersArrows,
			SpecialCharactersCurrency,
			SpecialCharactersEssentials,
			SpecialCharactersLatin,
			SpecialCharactersMathematical,
			SpecialCharactersText,
			Strikethrough,
			Style,
			Table,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			// TextPartLanguage, // 언어선택
			TextTransformation,
			// Title, // 제목
			TodoList,
			Underline,
			Undo,
			// MediaEmbed,
			FileRepository,
			DragDrop,
			VideoUpload
		],
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				// 'sourceEditing',
				// 'showBlocks',
				// 'findAndReplace',
				// 'textPartLanguage',
				// '|',
				// 'heading',
				'style',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'alignment',
				'specialCharacters',
				'horizontalLine',
				'|',
				// 'mediaEmbed',
				'videoUpload',
				'insertImage',
				'link',
				'insertTable',
				'highlight',
				'blockQuote',
				'codeBlock',
				'htmlEmbed',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'outdent',
				'indent',
			],
			shouldNotGroupWhenFull: false
		},
		fontFamily: {
			options: ['default', 'Arial', '궁서체', '바탕', '돋움'],
			supportAllValues: true
		},
		fontSize: {
			options: [10, 12, 'default', 16, 18, 20, 22],
			supportAllValues: true
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				},
			]
		},
		image: {
			toolbar: [
				'toggleImageCaption',
				'imageTextAlternative',
				'|',
				'imageStyle:inline',
				'imageStyle:wrapText',
				'imageStyle:breakText',
				'|',
				'resizeImage'
			]
		},
		language: 'ko',
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			decorators: {
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		placeholder: '최대 2048자까지 쓸 수 있습니다.',
		style: {
			definitions: [
				{
					name: 'Article category',
					element: 'h3',
					classes: ['category']
				},
				{
					name: 'Title',
					element: 'h2',
					classes: ['document-title']
				},
				{
					name: 'Subtitle',
					element: 'h3',
					classes: ['document-subtitle']
				},
				{
					name: 'Info box',
					element: 'p',
					classes: ['info-box']
				},
				{
					name: 'Side quote',
					element: 'blockquote',
					classes: ['side-quote']
				},
				{
					name: 'Marker',
					element: 'span',
					classes: ['marker']
				},
				{
					name: 'Spoiler',
					element: 'span',
					classes: ['spoiler']
				},
				{
					name: 'Code (dark)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-dark']
				},
				{
					name: 'Code (bright)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-bright']
				}
			]
		},
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
		},
		translations: [translations],
	};

	return (
		<EditorWrap>
			<div
				className="editor-container editor-container_classic-editor editor-container_include-style"
				ref={editorRef}
				style={{ width: editorWidth }}
			>
				<div className="editor-container__editor">
					{true && (
						<CKEditor
							editor={ClassicEditor}
							config={editorConfig}
							data={value}
							onReady={(editor) => {
								const toolbarElement = editor.ui.view.toolbar.element;
								// const editingContainer = editor.ui.getEditableElement();

								if (disabled) {
									editor.enableReadOnlyMode('readOnly');
									toolbarElement.style.display = 'none';
									// editingContainer.style.border = 'none';
								} else {
									editor.disableReadOnlyMode('readOnly');
									toolbarElement.style.display = 'flex';
									// editingContainer.style.border = '';
								}

								editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
									if (isReadOnly) {
										toolbarElement.style.display = 'none';
										// editingContainer.style.border = 'none';
									} else {
										toolbarElement.style.display = 'flex';
										// editingContainer.style.border = '';
									}
								});
							}}
							onChange={(event, editor) => {
								const data = editor.getData();
								onChangeValue(data);
							}}
						/>
					)}
				</div>
			</div>
		</EditorWrap>
	);
}
