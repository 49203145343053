import { useEffect,  useState } from "react";
import { getDateRangeForButton, getFormattedDate } from "util/DateSearchUtils";
import { callApi } from "util/HttpService";
import * as ExcelUtil from 'util/ExcelUtil';
import titleLogo from "asset/photomag_web/img/icon/filled/dashboard_b.svg"
import EqmtSelectBox from "components/web/cmmn/EqmtSelectBox";
import styled from "styled-components";
import EqmtByTypeChart from "./EqmtByTypeChart";
import EqmtDayMonthChart from "./EqmtDayMonthChart";
import moment from "moment";
import EqmtAccuHourlyChart from "./EqmtAccuHourlyChart";
import EqmtHourlyChart from "pages/web/sal-hist/EqmtHourlyChart";
import CustomDatePicker from "components/mobile/UI/DatePicker";
import CmmnRadioBtn from "components/mobile/cmmn/CmmnRadioBtn";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { useWindowSize } from "context/WindowSizeContext";
import CmmnGraphBox from "components/mobile/cmmn/CmmnGraphBox";
import CmmnMenuTitleWrap from "components/mobile/cmmn/CmmnMenuTitleWrap";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { useBrch } from "components/web/auth/BrchProvider";

const DashBoardWrap = styled.div`
	display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
	.dashboard-box {
		background-color: #fff;
		border-radius: 16px;
		width: 100%;
		margin-bottom: 20px;
		box-shadow: 0 0 10px rgba(0,0,0,.1);
	}
	@media (max-width: 1200px) {
		.search-option-wrap li:last-child {
			flex-direction: column;
			align-items: flex-start;
		}
		.option-wrap{
			flex-direction: column;
			align-items: flex-start;
			margin: 0 0 20px 0;
		}
		.radio-btn-wrap{
			margin: 10px 0 0 0;
		}
		.search-option-wrap li:first-child{
			margin-bottom: 10px;
		}
		.search-option-wrap li:last-child button{
			width: 100%;
		}
		.box-wrap li h2{
        	font-size: 24px;
    	}
	}
	.space-between {
		justify-content: space-between;
	}
	.alignset {
		display: flex;
		align-items: center;
	}
	.betweenSet {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.col-2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		& > div {
			width: 49.2%;
		}
	}
	.round-filled-small-btn:hover {
		color: var(--bs-btn-hover-color) !important;
		background-color: var(--bs-btn-hover-bg) !important;
		border-color: var(--bs-btn-hover-border-color) !important;
	}
`;
const SearchWrap = styled.ul`
	padding: 20px;
	li {
		&:first-child {
			margin-bottom: 20px;
		}
		&:last-child button {
			width: 130px;
		}
		&:not(.radio-btn-wrap) {
			display: flex;
    		align-items: center;
		}
		h3 {
			font-size: 16px;
			color: ${({ theme }) => theme.colors.dark2};
			font-weight: 500;
			min-width: 80px;
		}
	}
	.form-select {
		width: 380px;
	}
`;
// web
export default function SalHist() {
	// crdInfo는 natnCd때문에 불러옴
	const { brchId, crdInfo } = useBrch(); // 헤더에서 가맹점 변경 시 brchId변경됨
	const isMobile = useWindowSize();
	const { t } = useTranslation();

	const defaultSearchData = {
		brchId: brchId,
		eqmtId: "",
		startDate: "",
		endDate: "",
		division: "HST",
	};

	const dayButtons = [
		{ label: t("saleHistPage.today"), value: "saleHistPage.today" },
		{ label: t("saleHistPage.yesterday"), value: "saleHistPage.yesterday" },
		{ label: t("saleHistPage.thisWeek"), value: "saleHistPage.thisWeek" },
		{ label: t("saleHistPage.lastWeek"), value: "saleHistPage.lastWeek" },
		{ label: t("saleHistPage.lastWeekend"), value: "saleHistPage.lastWeekend" },
		{ label: t("saleHistPage.thisMonth"), value: "saleHistPage.thisMonth" },
		{ label: t("saleHistPage.lastMonth"), value: "saleHistPage.lastMonth" }
	];
	const hourTab = [t("saleHistPage.time")];
	const hourAccuTab = [t("saleHistPage.timeAcc")];
	const paymentTypeTab = [t("saleHistPage.sale"), t("saleHistPage.numberOfOccur")];
	const frameTab = [t("saleHistPage.frame"), t("saleHistPage.colorBackground"), t("saleHistPage.eventBackground"), t("saleHistPage.filter")];
	const dayTab = [t("saleHistPage.daily")];
	const monthTab = [t("saleHistPage.monthly")];

	const [searchData, setSearchData] = useState(defaultSearchData);
	const [radioValue, setRadioValue] = useState("saleHistPage.today");

	const [sumInfo, setSumInfo] = useState({});
	const [totSalesInfo, setTotSalesInfo] = useState({});
	const [totCntInfo, setTotCntInfo] = useState({});
	const [totFremCntInfo, setTotFremCntInfo] = useState({});
	// const [totBgrdCntInfo, setTotBgrdCntInfo] = useState({});
	const [totColorBgCntInfo, setTotColorBgCntInfo] = useState({});
	const [totEventBgCntInfo, setTotEventBgCntInfo] = useState({});
	const [totFilterCntInfo, setTotFilterCntInfo] = useState({});
	const [hourlySalesList, setHourlySalesList] = useState([]);
	const [dailySalesList, setDailySalesList] = useState([]);
	const [monthSalesList, setMonthSalesList] = useState([]);

	const [timeArray, setTimeArray] = useState([]);
	const [dayDateArray, setDayDateArray] = useState([]);
	const [monthDateArray, setMonthDateArray] = useState([]);

	/* 시간별 매출 */
	const hourlyArray = () => {
		const hours = [];
		for (let i = 0; i < 24; i++) {
			const hour = i < 10 ? `0${i}` : `${i}`;
			hours.push(`${hour}`);
		}
		return hours;
	};

	/* 일월별 매출 */
	const dayArray = () => {
		const today = moment();
		const result = {
			thisMonth: [],
			lastMonth: []
		};
		// 이번 달 날짜 배열 생성
		const startOfMonth = today.clone().startOf('month');
		for (let i = 0; i < today.date(); i++) {
			const currentDate = startOfMonth.clone().date(i + 1);
			const formattedDate = currentDate.format('YYYY-MM-DD');
			result.thisMonth.push(formattedDate);
		}
		// 저번 달 날짜 배열 생성
		const lastMonth = today.clone().subtract(1, 'month');
		for (let i = 0; i < lastMonth.daysInMonth(); i++) {
			const currentDate = lastMonth.clone().date(i + 1);
			const formattedDate = currentDate.format('YYYY-MM-DD');
			result.lastMonth.push(formattedDate);
		}
		return result;
	};
	const monthArray = () => {
		const today = moment();
		const result = {
			thisYearMonth: [],
			lastYearMonth: [],
		};
		for (let i = 11; i >= 0; i--) {
			const currentMonth = today.clone().subtract(i, 'months').format('YYYYMM');
			result.thisYearMonth.push(currentMonth);
		}
		for (let i = 12; i >= 1; i--) {
			const lastMonth = today.clone().subtract(i, 'months').format('YYYYMM');
			result.lastYearMonth.push(lastMonth);
		}
		return result;
	};

	useEffect(() => {
		
	}, []);

	useEffect(() => {
		handleButtonClick(t("saleHistPage.today"));
		const handleLanguageChange = () => {
			setRadioValue(prevValue => prevValue); // radioValue를 키 값으로 유지
		};
		i18next.on('languageChanged', handleLanguageChange);
		return () => {
			i18next.off('languageChanged', handleLanguageChange);
		};
		
	}, []);

	/* 검색버튼을 클릭할 때 조회 */
	const salesListSearch = async () => {
		setHourlySalesList([]);
		setDailySalesList([]);
		setMonthSalesList([]);

		try {
			const now = moment();
			const startOfToday = now.clone().startOf('day').format("YYYY-MM-DD");
			const startOfYesterday = now.clone().subtract(1, 'day').startOf('day').format("YYYY-MM-DD");
			const startOfMonth = now.clone().startOf('month').startOf('day').format("YYYY-MM-DD");
			const endOfMonth = now.clone().endOf('month').startOf('day').format("YYYY-MM-DD");
			const startOfLastMonth = now.clone().subtract(1, 'month').startOf('month').startOf('day').format("YYYY-MM-DD");
			const endOfLastMonth = now.clone().subtract(1, 'month').endOf('month').startOf('day').format("YYYY-MM-DD");

			const dates = dayArray();
			const months = monthArray();
			const isThisMonth = searchData.startDate === startOfMonth && searchData.endDate === endOfMonth;
			const isLastMonth = searchData.startDate === startOfLastMonth && searchData.endDate === endOfLastMonth;

			const totalCountInfoPromise = callApi("POST", searchData, "/web/api/sal-hist/eph/total");
			const sumListPromise = callApi("POST", searchData, "/web/api/sal-hist/eph/sum");

			let hourlySalesPromise = Promise.resolve([]);
			const isToday = searchData.startDate === startOfToday;
			const isYesterday = searchData.startDate === startOfYesterday;

			if (isToday || isYesterday) {
				const hourList = hourlyArray();
				setTimeArray(hourList);
				const hourParams = { ...searchData, hourList: hourList, division: "HST" };
				hourlySalesPromise = callApi("POST", hourParams, "/web/api/sal-hist/eph/hour-sales");
			} else {
				setHourlySalesList([]);
			}

			let dailySalesPromise = Promise.resolve([]);
			let monthlySalesPromise = Promise.resolve([]);
			if (!isThisMonth && !isLastMonth) {
				setDailySalesList([]);
			} else if (isThisMonth || isLastMonth) {
				const dayList = isThisMonth ? dates.thisMonth : dates.lastMonth;
				setDayDateArray(dayList);
				const params = { ...searchData, dayList: dayList };
				dailySalesPromise = callApi("POST", params, "/web/api/sal-hist/eph/daily-sales");

				const monthList = isThisMonth ? months.thisYearMonth : months.lastYearMonth;
				setMonthDateArray(monthList);
				const monthParams = { ...searchData, monthList: monthList };
				monthlySalesPromise = callApi("POST", monthParams, "/web/api/sal-hist/eph/month-sales");
			}

			const [totalCountInfo, hourlySales, sumList, dailySales, monthSales] = await Promise.all([
				totalCountInfoPromise,
				hourlySalesPromise,
				sumListPromise,
				dailySalesPromise,
				monthlySalesPromise
			]);
			// 시간별 매출 - 배열안의 값이 0인 배열은 빈 배열로 처리
			hourlySales.forEach((data, index) => {
				if (data.every(value => value === 0)) {
					hourlySales[index] = [];
				}
			});

			setSumInfo(sumList);
			setTotSalesInfo(totalCountInfo.stlmTotalSales);
			setTotCntInfo(totalCountInfo.stlmTotalCnt);
			setTotFremCntInfo(totalCountInfo.fremTotalCnt);
			// setTotBgrdCntInfo(totalCountInfo.bgrdTotalCnt);
			setTotColorBgCntInfo(totalCountInfo.colorBgTotalCnt);
			setTotEventBgCntInfo(totalCountInfo.eventBgTotalCnt);
			setTotFilterCntInfo(totalCountInfo.filterTotalCnt);
			setHourlySalesList(hourlySales);

			if (dailySales.length) {
				setDailySalesList(dailySales);
			}
			if (dailySales.length) {
				setMonthSalesList(monthSales);
			}

		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handelFormDataChange = (e) => {
		const { name, value } = e.target;
		setSearchData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handelDatePickerChange = (date, name) => {
		setSearchData((prevData) => ({
			...prevData,
			[name]: getFormattedDate(date, "yyyy-MM-dd"),
		}));
		setRadioValue('');
	};

	const handleButtonClickWrapper = (value) => {
		handleButtonClick(t(value));
		setRadioValue(value);
	};

	/* 기간 - 버튼을 클릭할 때 조회 */
	const handleButtonClick = async (btnNm) => {
		// console.log(btnNm);
		let { startDate, endDate } = getDateRangeForButton(btnNm, t);
		setSearchData((prevData) => ({
			...prevData,
			startDate: startDate,
			endDate: endDate,
		}));

		let params = { ...searchData, startDate: startDate, endDate: endDate };
		let totalCountInfoPromise, hourlySalesPromise, sumListPromise, dailySalesPromise, monthlySalesPromise;

		const now = moment();
		const startOfToday = now.clone().startOf('day').format("YYYY-MM-DD");
		const startOfYesterday = now.clone().subtract(1, 'day').startOf('day').format("YYYY-MM-DD");
		const startOfMonth = now.clone().startOf('month').startOf('day').format("YYYY-MM-DD");
		const endOfMonth = now.clone().endOf('month').startOf('day').format("YYYY-MM-DD");
		const startOfLastMonth = now.clone().subtract(1, 'month').startOf('month').startOf('day').format("YYYY-MM-DD");
		const endOfLastMonth = now.clone().subtract(1, 'month').endOf('month').startOf('day').format("YYYY-MM-DD");
		const dates = dayArray();
		const months = monthArray();

		switch (btnNm) {
			case t("saleHistPage.today"):
				console.log(`${btnNm} searchData`, params);
				const isToday = params.startDate === startOfToday;

				if (!isToday) {
					setHourlySalesList([]);
					hourlySalesPromise = Promise.resolve([]);
				} else {
					const hourList = hourlyArray();
					setTimeArray(hourList);
					const hourParams = { ...params, hourList: hourList, division: "HST" };
					// console.log(hourParams);
					hourlySalesPromise = callApi("POST", hourParams, "/web/api/sal-hist/eph/hour-sales");
				}
				break;

			case t("saleHistPage.yesterday"):
				console.log(`${btnNm} searchData`, params);

				const isYesterday = params.startDate === startOfYesterday;

				if (!isYesterday) {
					setHourlySalesList([]);
					hourlySalesPromise = Promise.resolve([]);
				} else {
					const hourList = hourlyArray();
					setTimeArray(hourList);
					const hourParams = { ...params, hourList: hourList, division: "HST" };
					hourlySalesPromise = callApi("POST", hourParams, "/web/api/sal-hist/eph/hour-sales");
				}
				break;
			case t("saleHistPage.thisWeek"):
			case t("saleHistPage.lastWeek"):
			case t("saleHistPage.lastWeekend"):
				console.log(`${btnNm} searchData`, params);
				break;

			case t("saleHistPage.thisMonth"):
			case t("saleHistPage.lastMonth"):
				console.log(`${btnNm} searchData`, params);

				const isThisMonth = params.startDate === startOfMonth && params.endDate === endOfMonth;
				const isLastMonth = params.startDate === startOfLastMonth && params.endDate === endOfLastMonth;

				if (!isThisMonth && !isLastMonth) {
					setDailySalesList([]);
					setMonthSalesList([]);
					dailySalesPromise = Promise.resolve([]);
					monthlySalesPromise = Promise.resolve([]);
				} else {
					const dayList = isThisMonth ? dates.thisMonth : dates.lastMonth;
					setDayDateArray(dayList);
					const newParams = { ...params, dayList: dayList };
					dailySalesPromise = callApi("POST", newParams, "/web/api/sal-hist/eph/daily-sales");

					const monthList = isThisMonth ? months.thisYearMonth : months.lastYearMonth;
					setMonthDateArray(monthList);
					const monthParams = { ...searchData, monthList: monthList };
					monthlySalesPromise = callApi("POST", monthParams, "/web/api/sal-hist/eph/month-sales");
				}
				break;

			default: break;
		}

		totalCountInfoPromise = callApi("POST", params, "/web/api/sal-hist/eph/total");
		sumListPromise = callApi("POST", params, "/web/api/sal-hist/eph/sum");

		try {
			const [totalCountInfo, hourlySales, sumList, dailySales, monthSales] = await Promise.all([
				totalCountInfoPromise,
				hourlySalesPromise || Promise.resolve([]),
				sumListPromise,
				dailySalesPromise || Promise.resolve([]),
				monthlySalesPromise || Promise.resolve([]),
			]);

			// 시간별 매출 - 배열안의 값이 0인 배열은 빈 배열로 처리
			hourlySales.forEach((data, index) => {
				if (data.every(value => value === 0)) {
					hourlySales[index] = [];
				}
			});
			// console.log(totalCountInfo.stlmTotalSales);
			setTotSalesInfo(totalCountInfo.stlmTotalSales);
			setTotCntInfo(totalCountInfo.stlmTotalCnt);
			setTotFremCntInfo(totalCountInfo.fremTotalCnt);
			// setTotBgrdCntInfo(totalCountInfo.bgrdTotalCnt);
			setTotColorBgCntInfo(totalCountInfo.colorBgTotalCnt);
			setTotEventBgCntInfo(totalCountInfo.eventBgTotalCnt);
			setTotFilterCntInfo(totalCountInfo.filterTotalCnt);
			setHourlySalesList(hourlySales);
			setSumInfo(sumList);

			if (dailySales.length) {
				const updatedDailySales = dailySales.map(data => data === null ? 0 : data);
				setDailySalesList(updatedDailySales);
			} else {
				setDailySalesList([]);
			}
			if (monthSales.length) {
				const updatedMonthSales = monthSales.map(data => data === null ? 0 : data);
				setMonthSalesList(updatedMonthSales);
			} else {
				setMonthSalesList([]);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const excelDownloadBtn = async () => {
		ExcelUtil.downloadExcelFromServer(`/web/api/sal-hist/eph/excel-down`, searchData, t("saleHistPage.saleStat"));
	}

	const handleCmmnBtnClick = (btnNm) => {
		switch (btnNm) {
			case "cmmnSearchBtn":
				salesListSearch();
				break;
			case "cmmnExcelDownloadBtn":
				excelDownloadBtn();
				break;
			default: break;
		}
	}
	
	const salesData = [
		{ label: "CARD", amount: totSalesInfo.cardAmt, ratio: totSalesInfo.cardRto, backgroundColor: "#e50200" },
		{ label: "CASH", amount: totSalesInfo.cashAmt, ratio: totSalesInfo.cashRto, backgroundColor: "#fcc355" },
		{ label: "CUPON", amount: totSalesInfo.cupnAmt, ratio: totSalesInfo.cupnRto, backgroundColor: "#37d1cc" },
		{ label: "POINT", amount: totSalesInfo.pontAmt, ratio: totSalesInfo.pontRto, backgroundColor: "#1a89e3" },
		{ label: "FREE", amount: totSalesInfo.freeAmt, ratio: totSalesInfo.freeRto, backgroundColor: "#a231d5" },
		{ label: "COIN", amount: totSalesInfo.evciAmt, ratio: totSalesInfo.evciRto, backgroundColor: "#ff6347" },
		{ label: "CASH+COIN", amount: totSalesInfo.csecAmt, ratio: totSalesInfo.csecRto, backgroundColor: "#32cd32" },
	];
	const salesCntData = [
		{ label: "CARD", amount: totCntInfo.cardCnt, ratio: totCntInfo.cardRto, backgroundColor: "#e50200" },
		{ label: "CASH", amount: totCntInfo.cashCnt, ratio: totCntInfo.cashRto, backgroundColor: "#fcc355" },
		{ label: "CUPON", amount: totCntInfo.cupnCnt, ratio: totCntInfo.cupnRto, backgroundColor: "#37d1cc" },
		{ label: "POINT", amount: totCntInfo.pontCnt, ratio: totCntInfo.pontRto, backgroundColor: "#1a89e3" },
		{ label: "FREE", amount: totCntInfo.freeCnt, ratio: totCntInfo.freeRto, backgroundColor: "#a231d5" },
		{ label: "COIN", amount: totCntInfo.evciCnt, ratio: totCntInfo.evciRto, backgroundColor: "#ff6347" },
		{ label: "CASH+COIN", amount: totCntInfo.csecCnt, ratio: totCntInfo.csecRto, backgroundColor: "#32cd32" },
	];
	const frmeData = [
		{ label: "CBH4C", amount: totFremCntInfo.cbh4cCnt, ratio: totFremCntInfo.cbh4cRto, backgroundColor: "#e50200" },
		{ label: "CSH6C", amount: totFremCntInfo.csh6cCnt, ratio: totFremCntInfo.csh6cRto, backgroundColor: "#fcc355" },
		{ label: "HSH4C", amount: totFremCntInfo.hsh4cCnt, ratio: totFremCntInfo.hsh4cRto, backgroundColor: "#37d1cc" },
		{ label: "HSW5C", amount: totFremCntInfo.hsw5cCnt, ratio: totFremCntInfo.hsw5cRto, backgroundColor: "#1a89e3" },
		{ label: "SSH4C", amount: totFremCntInfo.ssh4cCnt, ratio: totFremCntInfo.ssh4cRto, backgroundColor: "#9A2EFE" },
		{ label: "SSW4C", amount: totFremCntInfo.ssw4cCnt, ratio: totFremCntInfo.ssw4cRto, backgroundColor: "#3A01DF" },
	];
	const colorBgData = [
		{ label: "BLACK", amount: totColorBgCntInfo.cblackCnt, ratio: totColorBgCntInfo.cblackRto, backgroundColor: "#e50200" },
		{ label: "WHITE", amount: totColorBgCntInfo.cwhiteCnt, ratio: totColorBgCntInfo.cwhiteRto, backgroundColor: "#fcc355" },
		{ label: "GRAY", amount: totColorBgCntInfo.cgrayCnt, ratio: totColorBgCntInfo.cgrayRto, backgroundColor: "#37d1cc" },
		{ label: "BLUE", amount: totColorBgCntInfo.cblueCnt, ratio: totColorBgCntInfo.cblueRto, backgroundColor: "#1a89e3" },
		{ label: "GREEN", amount: totColorBgCntInfo.cgreenCnt, ratio: totColorBgCntInfo.cgreenRto, backgroundColor: "#8B4513" },
		{ label: "PURPLE", amount: totColorBgCntInfo.cpurpleCnt, ratio: totColorBgCntInfo.cpurpleRto, backgroundColor: "#FFA500" },
		{ label: "PURPLEMIX", amount: totColorBgCntInfo.cpurplemCnt, ratio: totColorBgCntInfo.cpurplemRto, backgroundColor: "#FFDEAD" },
		{ label: "PURPLEFOIL", amount: totColorBgCntInfo.cpurplefCnt, ratio: totColorBgCntInfo.cpurplefRto, backgroundColor: "#7CFC00" },
		{ label: "GREENMIX", amount: totColorBgCntInfo.cgreenmCnt, ratio: totColorBgCntInfo.cgreenmRto, backgroundColor: "#6B8E23" },
	];
	const eventBgData = [
		{ label: "MAGAZINE", amount: totEventBgCntInfo.ecmagzeCnt, ratio: totEventBgCntInfo.ecmagzeRto, backgroundColor: "#66CDAA" },
		{ label: "RAIN", amount: totEventBgCntInfo.esrainCnt, ratio: totEventBgCntInfo.esrainRto, backgroundColor: "#B0C4DE" },
		{ label: "ELEVATOR", amount: totEventBgCntInfo.ehelvtrCnt, ratio: totEventBgCntInfo.ehelvtrRto, backgroundColor: "#EE82EE" },
		{ label: "SEASON", amount: totEventBgCntInfo.ecsesnCnt, ratio: totEventBgCntInfo.ecsesnRto, backgroundColor: "#FFD700" },
		{ label: "TEEN1", amount: totEventBgCntInfo.ecteen1Cnt, ratio: totEventBgCntInfo.ecteen1Rto, backgroundColor: "#FFEBCD" },
		{ label: "TEEN2", amount: totEventBgCntInfo.ecteen2Cnt, ratio: totEventBgCntInfo.ecteen2Rto, backgroundColor: "#ff1493" },
		{ label: "LOCAL", amount: totEventBgCntInfo.eclocalCnt, ratio: totEventBgCntInfo.eclocalRto, backgroundColor: "#00ced1" },
		{ label: "LOCAL2", amount: totEventBgCntInfo.eclocal2Cnt, ratio: totEventBgCntInfo.eclocal2Rto, backgroundColor: "#007BFF" },
		{ label: "LOCAL3", amount: totEventBgCntInfo.eclocal3Cnt, ratio: totEventBgCntInfo.eclocal3Rto, backgroundColor: "#FF7F00" },
	];
	const filterData = [
		{ label: "ORIGINAL", amount: totFilterCntInfo.orglCnt, ratio: totFilterCntInfo.orglRto, backgroundColor: "#8FD9A8" },
		{ label: "WHITENING", amount: totFilterCntInfo.whtnCnt, ratio: totFilterCntInfo.whtnRto, backgroundColor: "#B0E0E6" },
		{ label: "COOL", amount: totFilterCntInfo.coolCnt, ratio: totFilterCntInfo.coolRto, backgroundColor: "#BA55D3" },
		{ label: "WARM", amount: totFilterCntInfo.warmCnt, ratio: totFilterCntInfo.warmRto, backgroundColor: "#FFA07A" },
		{ label: "GRAY", amount: totFilterCntInfo.grayCnt, ratio: totFilterCntInfo.grayRto, backgroundColor: "#D3D3D3" },
		{ label: "REVERSE", amount: totFilterCntInfo.rvrsCnt, ratio: totFilterCntInfo.rvrsRto, backgroundColor: "#FF69B4" },
	];

	const paymentTypeTabs = [
		{ tabTitle: t("saleHistPage.sale"), graphComponent: <EqmtByTypeChart salesData={salesData} unit={crdInfo && crdInfo.natnCd === "KR" ? "₩" : "¥"} label={t("saleHistPage.sale")} /> },
		{ tabTitle: t("saleHistPage.numberOfOccur"), graphComponent: <EqmtByTypeChart salesData={salesCntData} text={t("dashboardPage.occur")} label={t("saleHistPage.payment")} /> }
	];
	const fremTypeTabs = [
		{ tabTitle: t("saleHistPage.frame"), graphComponent: <EqmtByTypeChart salesData={frmeData} text={t("dashboardPage.occur")} label={t("common.selectLable")} /> },
		{ tabTitle: t("saleHistPage.colorBackground"), graphComponent: <EqmtByTypeChart salesData={colorBgData} text={t("dashboardPage.occur")} label={t("common.selectLable")} /> },
		{ tabTitle: t("saleHistPage.eventBackground"), graphComponent: <EqmtByTypeChart salesData={eventBgData} text={t("dashboardPage.occur")} label={t("common.selectLable")} /> },
		{ tabTitle: t("saleHistPage.filter"), graphComponent: <EqmtByTypeChart salesData={filterData} text={t("dashboardPage.occur")} label={t("common.selectLable")} /> },
	]
	const hourlyTypeTabs = [{ tabTitle: t("saleHistPage.time"), graphComponent: <EqmtHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={searchData} crdInfo={crdInfo} /> }];
	const accuHourlyTypeTabs = [{ tabTitle: t("saleHistPage.hourlyAccuSale"), graphComponent: <EqmtAccuHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={searchData} crdInfo={crdInfo} /> }];
	const dayTabs = [{ tabTitle: t("saleHistPage.daily"), graphComponent: <EqmtDayMonthChart salesData={dailySalesList} dateArray={dayDateArray} title={t("saleHistPage.daily")} label={t("saleHistPage.sale")} crdInfo={crdInfo} /> },];
	const monthTabs = [{ tabTitle: t("saleHistPage.monthly"), graphComponent: <EqmtDayMonthChart salesData={monthSalesList} dateArray={monthDateArray} title={t("saleHistPage.monthly")} label={t("saleHistPage.sale")} crdInfo={crdInfo} /> }];

	return (
		<div className="padding-box">
			<div className="title-wrap">
				<div className="title">
					<div className="alignset">
						<img src={titleLogo} alt={t("saleHistPage.saleStat")} />
						<h2>{t("saleHistPage.saleStat")}</h2>
					</div>
					<p>Home {">"} {t("saleHistPage.saleStat")}</p>
				</div>
			</div>
			<DashBoardWrap>
				<div className="dashboard-box">
					<SearchWrap className="search-option-wrap">
						<li>
							<h3>{t("saleHistPage.eqmtNm")}</h3>
							<EqmtSelectBox
								defaultVal={searchData.eqmtId}
								tagName={"eqmtId"}
								defaultOptTxt={t("saleHistPage.entire")}
								brchId={searchData.brchId}
								onSelectChange={handelFormDataChange}
							/>
						</li>
						<li className="space-between">
							<div className="alignset">
								<h3>{t("common.periodLable")}</h3>
								<div className="alignset option-wrap">
									<CustomDatePicker
										defaultStartDate={searchData.startDate}
										defaultEndDate={searchData.endDate}
										onSelectChange={handelDatePickerChange}
										isMobile={isMobile}
									/>
								</div>
								<CmmnRadioBtn
									dataArray={dayButtons.map(button => ({ ...button, label: t(button.value) }))}
									onSelectChange={(e) => { handleButtonClickWrapper(e.target.value); }}
									className="radio-btn-wrap"
									defaultVal={radioValue}
									isMobile={isMobile}
								/>
							</div>
							<ButtonUtil
								value={t("common.searchLable")}
								className="btn btn-danger round-filled-small-btn small-btn"
								name="cmmnSearchBtn"
								onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
							/>
						</li>
					</SearchWrap>
				</div>
				<CmmnMenuTitleWrap
					title={t("leftMenu.salHist")}
					searchData={searchData}
					dataInfo={sumInfo}
					isMobile={isMobile}
					onCmmnBtnClick={handleCmmnBtnClick}
					crdInfo={crdInfo}
				/>
				<div className="col-2">
					<CmmnGraphBox
						title={t("saleHistPage.paymentType")}
						tabs={paymentTypeTab}
						graphComponents={paymentTypeTabs.map(tab => tab.graphComponent)}
					/>
					<CmmnGraphBox
						title={t("saleHistPage.frameAndBackgroundSelectCnt")}
						tabs={frameTab}
						graphComponents={fremTypeTabs.map(tab => tab.graphComponent)}
					/>
				</div>
				{hourlySalesList.every(data => data.length === 0) ?
					<></>
					:
					<div className="col-2">
						<CmmnGraphBox
							title={t("saleHistPage.salesByHour")}
							// subTitle="선택 기간이 오늘 또는 어제일 경우"
							tabs={hourTab}
							graphComponents={hourlyTypeTabs.map(tab => tab.graphComponent)}
						/>
						<CmmnGraphBox
							title={t("saleHistPage.hourlyAccuSale")}
							// subTitle="선택 기간이 오늘 또는 어제일 경우"
							tabs={hourAccuTab}
							graphComponents={accuHourlyTypeTabs.map(tab => tab.graphComponent)}
						/>
					</div>
				}
				{(dailySalesList.every(data => data === 0) && monthSalesList.every(data => data === 0)) ?
					<></>
					:
					<div className="col-2">
						<CmmnGraphBox
							title={`${t("saleHistPage.daily")} ${t("saleHistPage.sale")}`}
							tabs={dayTab}
							graphComponents={dayTabs.map(tab => tab.graphComponent)}
						/>
						<CmmnGraphBox
							title={`${t("saleHistPage.monthly")} ${t("saleHistPage.sale")}`}
							tabs={monthTab}
							graphComponents={monthTabs.map(tab => tab.graphComponent)}
						/>
					</div>
				}
			</DashBoardWrap>
		</div>
	)
}